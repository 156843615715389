import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=DateTime",
        "componentName": "DateTime"
      }}>{`<DateTime date={new Date().toISOString()} />
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="DateTime" mdxType="Props" />
    <h2 {...{
      "id": "date",
      "style": {
        "position": "relative"
      }
    }}>{`Date`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Timestamp string of the date to be displayed. Provide this in the ISO8601 format
that is understood by the
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date"
      }}>{`native Date object`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=DateTime",
        "componentName": "DateTime"
      }}>{`<DateTime date={new Date().toISOString()} />
`}</code></pre>
    <h2 {...{
      "id": "format",
      "style": {
        "position": "relative"
      }
    }}>{`Format`}</h2>
    <p><inlineCode parentName="p">{`default: dateTimeFormatDefault`}</inlineCode></p>
    <p>{`Date and time formatting setting. Refer to the
`}<a parentName="p" {...{
        "href": "https://day.js.org/docs/en/display/format"
      }}>{`dayjs docs`}</a>{` for all available
settings.`}</p>
    <p>{`On top of the dayjs formats, you can also supply `}<inlineCode parentName="p">{`"relative"`}</inlineCode>{`. Under the hood
this uses dayjs' RelativeTime plugin.`}</p>
    <p>{`Each brand has `}<a parentName="p" {...{
        "href": "/foundations/tokens/javascript/#h-datetime"
      }}>{`a few tokens`}</a>{` which
contain some default formats.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=DateTime tokens=dateTimeFormatDefault,dateTimeFormatDate,dateTimeFormatTime,dateTimeFormatDateTime",
        "componentName": "DateTime",
        "tokens": "dateTimeFormatDefault,dateTimeFormatDate,dateTimeFormatTime,dateTimeFormatDateTime"
      }}>{`<Box p={5}>
  <DateTime date={new Date().toISOString()} format={dateTimeFormatDefault} />
</Box>
<Box p={5}>
  <DateTime date={new Date().toISOString()} format={dateTimeFormatDate} />
</Box>
<Box p={5}>
  <DateTime date={new Date().toISOString()} format={dateTimeFormatTime} />
</Box>
<Box p={5}>
  <DateTime date={new Date().toISOString()} format={dateTimeFormatDateTime} />
</Box>
<Box p={5}>
  <DateTime date={new Date(Date.now() - 1000 * 60 * 60 * 24).toISOString()} format="relative" />
</Box>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      